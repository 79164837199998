<template>
    <div v-if="x != null">
        <v-card class="mx-auto mt-5" max-width="1100">
            <v-row class="pa-5">
                <v-col v-for="(att, i) in x.attachments" :key="i" class="d-flex child-flex" cols="3">
                    <v-img :src="att.path" aspect-ratio class="grey lighten-2"></v-img>
                </v-col>
            </v-row>

            <v-card-title> {{ x.title }} </v-card-title>
            <v-card-subtitle>{{ x.created | formatDate }}</v-card-subtitle>
            <v-card-text>{{ x.body }}</v-card-text>


            <v-card-actions class="px-4">
                <div>
                    <span> {{ x.likesCount }} <v-icon color="info lighten-2">mdi-thumb-up</v-icon></span>
                    <span class="mr-3"> {{ x.disLikesCount }} <v-icon color="secondary">mdi-thumb-down</v-icon> </span>
                    <span class="mr-3"> {{ x.lovesCount }} <v-icon color="error lighten-2">mdi-heart</v-icon> </span>
                    <span class="mr-3"> {{ x.commentsCount }} <v-icon color="primary">mdi-comment</v-icon> </span>
                </div> 
                <v-spacer></v-spacer>
                <v-btn text @click="show = !show">
                     عرض التعليقات
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-expand-transition>
                <div v-show="show">
                    <v-divider></v-divider>
                    <v-card-text>                        
                        <div v-for="(comment, i) in x.comments" :key="i">
                            <v-card flat v-if="show" class="d-flex justify-space-between grey lighten-4 pa-2 mx-2 mb-2">
                                <div  class="d-flex justify-space-between">
                                    <v-avatar size="40">
                                        <v-img :src="comment.fromUser.imageUrl"></v-img>
                                    </v-avatar>
                                    <div>
                                        <v-card flat class="mr-2 px-4 py-1">
                                            <div> {{ comment.fromUser.fullName }} </div>
                                            <div> {{ comment.comment }} </div>
                                        </v-card>
                                        <div class="font-small grey--text text--lighten-1 mr-2" >{{ comment.created | timeSince }}</div>
                                    </div>
                                </div>

                                <v-btn @click="deleteComment(comment, i)" color="error" icon>
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-card>
                        </div>
                    </v-card-text>
                </div>
            </v-expand-transition>


            <v-card-actions class="mt-2">
                <v-btn @click="goBack()" color="primary secondary--text" large> رجوع <v-icon>keyboard_arrow_left</v-icon> </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            x: this.$route.params.obj,

            show: false,
        };
    },

    methods: {
        goBack() {
            window.history.go(-1);
        },
        async deleteComment(item, i) {
            this.$global.state.loading = true;
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$http
                        .delete(`NewsComments/${item.id}`)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.x.comments.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        })
                        .finally(() => (this.$global.state.loading = false));
                }
            });
        },
    },
};
</script>
